import VuePortal from '@linusborg/vue-simple-portal'
import flits from '@plugins/flits'
import router from '@shared/router'
import dialog from '@ts/components/v-dialog'
import Vue from 'vue'

Vue.use(VuePortal, {
  name: 'portal',
})

Vue.use(dialog)
Vue.use(flits, { router })
