import i18n from '@plugins/i18n'
import Logger, { LoggerInterface } from '@shared/error-logger'
import router from '@shared/router'
import '@ts/shared/components'
import '@ts/shared/directives'
import '@ts/shared/filters'
import { empty } from '@ts/shared/helpers'
import '@ts/shared/plugins'
import akApp from '@views/app/index.vue'
import VueCompositionApi from '@vue/composition-api'
import Vue from 'vue'

Vue.use(VueCompositionApi)

if (typeof _state === 'undefined') {
  (window as any)._state = {}
}

class Application {
  private logger: LoggerInterface

  constructor() {
    this.requireIcons()
    this.initLogger()
    this.init()
  }

  private init() {
    const app = new Vue({
      router,
      i18n,
      components: {
        'v-app': akApp,
      },
      render(h) {
        return h('v-app')
      },
      mounted() {
        const isAuthenticated = !empty(_state.user)

        if (!isAuthenticated) {
          router.push({ name: 'login' })
        }
      },
    })

    app.$mount(document.getElementById('ak-app'))
  }

  private initLogger() {
    this.logger = new Logger()

    Vue.config.errorHandler = (err, vm, info) => {
      this.logger.addErrorRecord(err)
      if (DEBUG) {
        throw err
      }
    }
  }

  private requireIcons() {
    const div = document.createElement('div')
    const body = document.body
    const cssString =
      'border: 0; clip: rect(0 0 0 0); height: 0; overflow: hidden; padding: 0; position: absolute; width: 0;'

    div.style.cssText = cssString
    div.innerHTML = require('!!html-loader!../img/icons.svg')
    body.insertBefore(div, body.childNodes[0])
  }
}

export default new Application()
