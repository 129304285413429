import Vue from 'vue'
import vDeleteConfirm from '../components/v-delete-confirm/index.vue'
import vIcon from '../components/v-icon/index.vue'
import vLoader from '../components/v-loader/index.vue'
import vPromiseButton from '../components/v-promise-button/index.vue'

Vue.component('v-icon', vIcon)
Vue.component('v-loader', vLoader)
Vue.component('v-delete-confirm', vDeleteConfirm)
Vue.component('v-promise-button', vPromiseButton)
