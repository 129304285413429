export default [
  {
    path: '/',
    component: () => import('@views/app/main/index.vue'),
    children: [
      {
        path: '',
        name: 'items',
        component: () => import('@views/app/main/items/index/index.vue'),
      },
      {
        path: 'items/create',
        name: 'item-create',
        component: () => import('@views/app/main/items/item/update/index.vue'),
      },
      {
        path: 'items/:item',
        component: () => import('@views/app/main/items/item/index.vue'),
        children: [
          {
            path: '',
            name: 'item',
            component: () => import('@views/app/main/items/item/view/index.vue'),
          },
          {
            path: 'update',
            name: 'item-update',
            component: () => import('@views/app/main/items/item/update/index.vue'),
          },
          {
            path: 'sections/create',
            name: 'section-create',
            component: () => import('@views/app/main/items/item/section/update/index.vue'),
          },
          {
            path: 'sections/:section',
            name: 'section',
            component: () => import('@views/app/main/items/item/section/view/index.vue'),
          },
          {
            path: 'sections/:section/questions/create',
            name: 'question-create',
            component: () => import('@views/app/main/items/item/section/question/index.vue'),
          },
          {
            path: 'sections/:section/questions/:question/update',
            name: 'question-update',
            component: () => import('@views/app/main/items/item/section/question/index.vue'),
          },
        ],
      },
      {
        path: 'surveys',
        component: () => import('@views/app/main/surveys/survey/index.vue'),
        children: [
          {
            path: '',
            name: 'surveys-index',
            component: () => import('@views/app/main/surveys/index/index.vue'),
          },
          {
            path: ':survey/question',
            name: 'survey-question',
            component: () => import('@views/app/main/surveys/survey/question/index.vue'),
          },
          {
            path: ':survey/practice',
            name: 'survey-practice',
            component: () => import('@views/app/main/surveys/survey/question/index.vue'),
          },
          {
            path: ':survey/results',
            name: 'survey-results',
            component: () => import('@views/app/main/surveys/survey/results/index.vue'),
          },
          {
            path: ':survey/finished',
            name: 'survey-finished',
            component: () => import('@views/app/main/surveys/survey/finished/index.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@views/app/auth/index.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@views/app/auth/login/index.vue'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@views/app/auth/register/index.vue'),
      },
      {
        path: 'password/request',
        name: 'request',
        component: () => import('@views/app/auth/passwords/request/index.vue'),
      },
      {
        path: 'password/reset/:token',
        name: 'reset',
        component: () => import('@views/app/auth/passwords/reset/index.vue'),
      },
    ],
  },
]
